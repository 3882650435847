<template>
  <div>
    <div class="main-loader" v-if="isLoading"></div>
    <div class="feed-page" v-else>
      <b-container>
        <b-row>
          <!-- Swiper Component for Topics -->
          <swiper :options="swiperOptions" class="swiper-container mt-1">
            <swiper-slide v-for="(tab, index) in tabs" :key="index" class="ss-width">
              <b-button 
                :variant="isSelected(tab.topic) ? 'dark' : 'outline-secondary'" 
                @click="toggleTopic(tab.topic)" 
                class="tab-button"
              >
                {{ tab.topic }}
              </b-button>
            </swiper-slide>
          </swiper>
        </b-row>

        <b-row v-if="filteredFeedImages.length">
          <product-card 
            v-for="image in paginatedFeedImages"
            :key="image.id"
            :product="image"
            :show-like-button="false"
            :show-share-button="true"
            @toggle-like="handleToggleLike"
          />
        </b-row>

        <div class="no-data mt-5 mb-5" v-else-if="!isLoading && !filteredFeedImages.length">
          <div class="mx-auto text-center">
            <h2></h2>
          </div>
        </div>

        <!-- The Sentinel element for triggering infinite scroll -->
        <div ref="loadMoreTrigger" class="infinite-scroll-trigger"></div>
      </b-container>
    </div>
  </div>
</template>


<script>
import productCard from './imageCard.vue';
import { mapGetters, mapActions } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { openDB } from 'idb'; // Import IndexedDB library

export default {
  name: 'FeedPage',
  components: {
    Swiper,
    SwiperSlide,
    productCard
  },
  data() {
    return {
      noDataMessage: 'Updating feed...',
      isLoading: false,
      tabs: [],
      selectedTopics: [], // Array to store multiple selected topics
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1024: {
            slidesPerView: 'auto',
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 'auto',
            spaceBetween: 5,
          },
          640: {
            slidesPerView: 'auto',
            spaceBetween: 5,
          },
          320: {
            slidesPerView: 'auto',
            spaceBetween: 5,
          },
        },
      },
      imagesPerPage: 30,
      currentPage: 1,
      observer: null,
    };
  },
  computed: {
    ...mapGetters('app', ['getFeedImages']),
    feedImages() {
      return this.getFeedImages;
    },
    errorMessage() {
      return this.getErrorMessage;
    },
    filteredFeedImages() {
      // Filter images based on selected topics
      if (this.selectedTopics.length > 0) {
        return this.feedImages.filter(image => this.selectedTopics.includes(image.topic));
      }
      return this.feedImages;
    },
    paginatedFeedImages() {
      return this.filteredFeedImages.slice(0, this.imagesPerPage * this.currentPage);
    },
    hasMoreImages() {
      return this.paginatedFeedImages.length < this.filteredFeedImages.length;
    },
  },
  async created() {
    await this.initDB();
    this.loadFeedImagesFromStorage();
    this.generateTabs();
    
    const storedTopics = JSON.parse(localStorage.getItem('selectedTopics')) || [];
    this.selectedTopics = storedTopics;
    
    await this.fetchFeedImages();
    this.generateTabs();
  },
  mounted() {
    this.initializeIntersectionObserver();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    
  ...mapActions('app', ['fetchFeedImages', 'loadFeedImagesFromStorage', 'updateLikeStatus']),
  
  handleToggleLike(imageId, currentLikeStatus) {
    const user = JSON.parse(localStorage.getItem('userdetails'));
    const userId = user?.user_id;

    if (!userId) {
      this.$bvModal.show('login-modal');
      return;
    }

    const newLikeStatus = currentLikeStatus === 'Y' ? 'N' : 'Y';

    this.updateLikeStatus({ userId, imageId, likeStatus: newLikeStatus });
  },

  // Single method to handle both selecting and unselecting topics
  toggleTopic(topic) {
    // Toggle topic selection
    if (this.isSelected(topic)) {
      this.selectedTopics = this.selectedTopics.filter(selectedTopic => selectedTopic !== topic);
    } else {
      this.selectedTopics.push(topic);
    }

    // Store updated selected topics in localStorage
    localStorage.setItem('selectedTopics', JSON.stringify(this.selectedTopics));

    // Rearrange tabs dynamically after selection change
    this.generateTabs();

    // Reset pagination to the first page
    this.currentPage = 1;
  },

  generateTabs() {
    const topics = [...new Set(this.feedImages.map(image => image.topic))];

    // Separate selected and non-selected topics
    const selectedTopics = topics.filter(topic => this.selectedTopics.includes(topic));
    const nonSelectedTopics = topics.filter(topic => !this.selectedTopics.includes(topic));

    // Merge selected topics first, followed by non-selected topics
    const orderedTopics = [...selectedTopics, ...nonSelectedTopics];

    // Update the tabs array, marking selected topics as active
    this.tabs = orderedTopics.map(topic => ({
      topic,
      active: this.isSelected(topic),
    }));
  },

  // Helper function to check if a topic is selected
  isSelected(topic) {
    return this.selectedTopics.includes(topic);
  },

    async initDB() {
      this.db = await openDB('briks-feed-db', 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('feed-images')) {
            db.createObjectStore('feed-images', { keyPath: 'id' });
          }
        },
      });
    },
    async loadFeedImagesFromStorage() {
      const tx = this.db.transaction('feed-images', 'readonly');
      const store = tx.objectStore('feed-images');
      const allImages = await store.getAll();
      this.$store.commit('app/SET_FEED_IMAGES', allImages);
    },
    async fetchFeedImages() {
      this.isLoading = true;
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}getFeed`);
        const data = await response.json();
        if (Array.isArray(data.images)) {
          await this.saveImagesToDB(data.images);
          this.$store.commit('app/SET_FEED_IMAGES', data.images);
        } else {
          console.error('Expected an array of feed images, but got:', data.images);
        }
      } catch (error) {
        console.error('Error fetching feed images:', error);
      }
      this.isLoading = false;
    },
    async saveImagesToDB(images) {
      const tx = this.db.transaction('feed-images', 'readwrite');
      const store = tx.objectStore('feed-images');
      for (const image of images) {
        await store.put(image);
      }
      await tx.done;
    },
    loadMoreImages() {
      if (this.hasMoreImages) {
        this.currentPage++;
      }
    },
    initializeIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.loadMoreImages();
          }
        });
      }, options);

      if (this.$refs.loadMoreTrigger) {
        this.observer.observe(this.$refs.loadMoreTrigger);
      }
    },
  },
};
</script>


<style scoped>

/* Swiper and tab styles */
.swiper-slide {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: auto !important;
  box-sizing: border-box;
}

.swiper-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-left: 6px !important;
  margin-right: 6px !important;
  margin-top: 15px !important;
  padding-bottom: 10px !important;
}

.swiper-container::-webkit-scrollbar {
  display: none;
}

.tab-button {
  font-size: 1rem !important;
  margin-bottom: 8px;
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0.48571rem 0.71429rem !important;
  display: inline-flex;
  align-items: center !important;
  justify-content: center !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: hsl(0, 0%, 100%);
  transition: all 0.2s ease-in-out;
}

.tab-button:hover,
.tab-button.is-active {
  background-color: #0069d9;
}

.b-button {
  border-radius: 0.25rem;
}

.ss-width {
  width: auto !important;
}

.infinite-scroll-trigger {
  width: 100%;
  height: 50px;
}
</style>
